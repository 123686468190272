<template lang="pug">
  article.home
    h1.sr-only Aro Archive
    //- slices...
    template(v-for="slice in doc.body", )
      //- (home hover videos)
      template(v-if="slice.slice_type === 'home_hover_videos'")
        .fixed.overlay.z-10.flex.flex-col
          //- links...
          template(v-for="(item, i) in slice.items")
            //- link
            app-link.flex.items-center.justify-center.text-white.relative.overflow-hidden(:link="item.link", :style="{flex: `1 0 ${1 / slice.items.length * 100}%`}", @mouseenter.native="current = i")
              //- title
              h2.text-30.lg_text-60.relative.z-10.font-light {{ $prismic.asText(item.heading) }}
              //- (mobile vimeo poster)
              resp-img(v-if="!canHover && item.vimeo_poster.url", :image="item.vimeo_poster", :bg="true")
              //- (mobile vimeo)
              vimeo-player.transition.duration-500(v-if="!canHover", :vimeoURL="item.vimeo.url", :clip="[item.vimeo_clip_start, item.vimeo_clip_end]", bgSize="cover", )

        //- (laptop videos layer)
        figure.fixed.overlay(v-if="canHover")
          //- videos...
          .absolute.overlay(v-for="(item, i) in slice.items", :class="{'opacity-0': i !== current}")
            //- poster / loading image
            resp-img(v-if="item.vimeo_poster.url", :image="item.vimeo_poster", :bg="true")
            //- vimeo
            vimeo-player.transition.duration-500(:vimeoURL="item.vimeo.url", :clip="[item.vimeo_clip_start, item.vimeo_clip_end]", bgSize="cover", )
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
export default {
  name: 'Home',
  data () {
    return {
      current: 0
    }
  },
  computed: {
    doc () {
      return this.$store.state.home
    },
    ...mapState(['canHover'])
  },
  async beforeRouteEnter (to, from, next) {
    await store.dispatch('getHome')
    return next()
  }
}
</script>

<style>
</style>
